import React, { useRef, useState } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { transparentize } from 'polished'
import Alert from 'react-s-alert'
import ReCAPTCHA from 'react-google-recaptcha'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { CONTAINER_MAX_WIDTH, ERROR_MESSAGE } from 'consts'
import { pxToRem } from 'helpers'
import { Box, Flex } from 'components/Layout'
import { Text } from 'components/Typography'
import Input from 'components/Input'
import Container from 'components/Container'
import ButtonWithLoading from 'components/ButtonWithLoading'

const StyledGetStartedButton = styled(ButtonWithLoading)`
  background: #ffffff;
  box-shadow: 0px 18px 24px -8px rgba(216, 233, 255, 0.32);
  border-radius: ${radius.l};
  height: 100%;
`

const StyledWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  background: radial-gradient(50% 81.39% at 50% 50%, #0e6ce7 0%, #005ed7 46.67%, #0054c0 100%);
  overflow: hidden;
`

const BorderedBox = styled(Box)`
  border-radius: ${radius.pill};
  z-index: 0;
  position: absolute;
  border: 1px solid ${({ amount }) => transparentize(1 - amount, colors.white)};
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledInput = styled(Input)`
  border: 1px solid #b6cef0;
  height: ${pxToRem(52)};
`

const { FIRST_NAME, LAST_NAME, EMAIL } = { FIRST_NAME: 'first_name', LAST_NAME: 'last_name', EMAIL: 'email' }

const FormValidationSchema = Yup.object().shape({
  [FIRST_NAME]: Yup.string()
    .max(25, 'First name is too long - should be 25 chars maximum.')
    .matches(/^[^:/<>%$().,";~+-?!|]*$/, 'Please enter valid first name.')
    .required('First name is required.'),
  [LAST_NAME]: Yup.string()
    .max(25, 'Last name is too long - should be 25 chars maximum.')
    .matches(/^[^:/<>%$().,";~+-?!|]*$/, 'Please enter valid last name.')
    .required('Last name is required.'),
  [EMAIL]: Yup.string()
    .max(50, 'Email is too long - should be 50 chars maximum.')
    .required('Email is required.')
    .email('Please use a valid email address.'),
})

const NewsletterSubScription = ({ type = 'newsletter' }) => {
  const recaptchaRef = useRef()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onRecaptchaExpired = () => {
    recaptchaRef.current.execute()
  }

  const handleSubmitForm = async (values) => {
    try {
      let recaptcha

      if (recaptchaRef && recaptchaRef.current) {
        recaptcha = recaptchaRef.current.getValue()

        if (!recaptcha) {
          await recaptchaRef.current.executeAsync()

          recaptcha = recaptchaRef.current.getValue()

          recaptchaRef.current.reset()
        }
      } else {
        recaptcha = 'disabled'
      }

      if (!recaptcha || recaptcha === 'disabled') {
        Alert.error(`Error verifying reCAPTCHA, please try again or contact support.`, { timeout: 5000 })
      } else {
        setIsSubmitting(true)

        const response = await fetch(`${process.env.GATSBY_PUBLIC_GATSBY_API_URL}/subscribe`, {
          body: JSON.stringify({
            [FIRST_NAME]: values[FIRST_NAME],
            [LAST_NAME]: values[LAST_NAME],
            [EMAIL]: values[EMAIL],
            type,
            recaptcha,
          }),
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        })

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          setIsSubmitting(false)
        } else {
          navigate('/thankyou')
        }
      }
    } catch (error) {
      navigate('/500')
    }
  }

  return (
    <StyledWrapper>
      <BorderedBox width="789px" height="791px" amount={0.1} />
      <BorderedBox width="931px" height="933px" amount={0.2} />
      <BorderedBox width="1107px" height="1109px" amount={0.27} />
      <BorderedBox width="1309px" height="1310px" amount={0.35} />
      <BorderedBox width="1543px" height="1546px" amount={0.42} />
      <BorderedBox width="1839px" height="1842px" amount={0.35} />
      <Container
        my={{ mobile: 'l', tablet: 'xxl' }}
        py={{ mobile: 'l', tablet: 'xl' }}
        pl={{ mobile: 'l', tablet: 'l' }}
        pr={{ mobile: 'l', tablet: 'l' }}
        position="relative"
        maxWidth={CONTAINER_MAX_WIDTH}
        width="100%"
        height="100%"
      >
        <Flex flexDirection="column" width="100%" zIndex="2">
          <Flex alignItems="center" flexDirection="column" position="relative">
            <Flex>
              <Text fontWeight="extraBold" fontSize="3xl" color="white">
                Subscribe to our Newsletter!
              </Text>
            </Flex>

            <Flex alignItems="center" flexDirection="column" mx="auto">
              <Text color={COLOR_CONSTANTS.SALT} fontSize="l">
                to stay updated on the latest and greatest Social Media news
              </Text>
              <Text color={COLOR_CONSTANTS.SALT} fontSize="l">
                We promise not to spam you!
              </Text>
            </Flex>
            <Flex mt="l" pt="m" justifyContent="center">
              <Formik
                initialValues={{ [FIRST_NAME]: '', [LAST_NAME]: '', [EMAIL]: '' }}
                validationSchema={FormValidationSchema}
                onSubmit={handleSubmitForm}
              >
                {({ values, handleChange, handleSubmit, touched, errors }) => {
                  return (
                    <Flex flexDirection="column" alignItems="center">
                      <Box>
                        <StyledInput
                          width={{ mobile: '200px', mobileLarge: '360px' }}
                          isLarge
                          placeholder="First Name"
                          value={values[FIRST_NAME]}
                          onChange={handleChange(FIRST_NAME)}
                          error={errors[FIRST_NAME] && touched[FIRST_NAME] && errors[FIRST_NAME]}
                          errorColor={COLOR_CONSTANTS.WHITE}
                          autoComplete=""
                        />
                      </Box>

                      <Box mt="sm">
                        <StyledInput
                          width={{ mobile: '200px', mobileLarge: '360px' }}
                          isLarge
                          placeholder="Last Name"
                          value={values[LAST_NAME]}
                          onChange={handleChange(LAST_NAME)}
                          error={errors[LAST_NAME] && touched[LAST_NAME] && errors[LAST_NAME]}
                          errorColor={COLOR_CONSTANTS.WHITE}
                          autoComplete=""
                        />
                      </Box>

                      <Box mt="sm">
                        <StyledInput
                          width={{ mobile: '200px', mobileLarge: '360px' }}
                          placeholder="email@example.com"
                          value={values[EMAIL]}
                          onChange={handleChange(EMAIL)}
                          isLarge
                          error={errors[EMAIL] && touched[EMAIL] && errors[EMAIL]}
                          errorColor={COLOR_CONSTANTS.WHITE}
                          autoComplete=""
                        />
                      </Box>

                      <Flex
                        flexDirection="column"
                        mt="sm"
                        height={pxToRem(52)}
                        width={{ mobile: '200px', mobileLarge: '360px' }}
                      >
                        <StyledGetStartedButton
                          type="submit"
                          isLoading={isSubmitting}
                          onClick={() => {
                            if (!isSubmitting) {
                              handleSubmit()
                            }
                          }}
                        >
                          <Text fontSize="l" color="primary" fontWeight="bold">
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                          </Text>
                        </StyledGetStartedButton>
                      </Flex>
                    </Flex>
                  )
                }}
              </Formik>
            </Flex>
          </Flex>

          <ReCAPTCHA
            sitekey="6Ld6gM0gAAAAAPwkW5CO806wVq_C1BanZ12XeZyI"
            size="invisible"
            ref={recaptchaRef}
            onExpired={onRecaptchaExpired}
          />
        </Flex>
      </Container>
    </StyledWrapper>
  )
}

NewsletterSubScription.defaultProps = {
  type: 'insights',
}

NewsletterSubScription.propTypes = {
  type: PropTypes.string,
}

export default NewsletterSubScription
