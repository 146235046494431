import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { pxToRem } from 'helpers'
import { COLOR_CONSTANTS, space, colors, radius } from 'theme'
import { CONTAINER_MAX_WIDTH } from 'consts'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import NewsletterSubScription from 'components/NewsletterSubScription'
import Link from 'components/Link'

import imageHeader from 'static/images/ai_assistant/header_picture.webp'
import imageGenerateSocialPosts from 'static/images/ai_assistant/image_generate_social_posts.webp'
import imageAIAssistantFeature from 'static/images/ai_assistant/image_ai_assistant_feature.webp'
import imageAIAssistantReplies from 'static/images/ai_assistant/image_ai_assistant_replies.webp'

import imageStar from 'static/images/reviews/star.svg'

import networkImageBluesky from 'static/images/networks/bluesky.svg'
import networkImageFacebook from 'static/images/networks/facebook.svg'
import networkImageInstagtam from 'static/images/networks/instagram.svg'
import networkImageLinkedIn from 'static/images/networks/linkedin.svg'
import networkImageTwitter from 'static/images/networks/twitter.svg'
import networkImageGoogle from 'static/images/networks/googlebusiness.svg'
import networkImageTikTok from 'static/images/networks/tiktok.svg'
import networkImageYouTube from 'static/images/networks/youtube.svg'
import networkImageReddit from 'static/images/networks/reddit.svg'
import networkImagePinterest from 'static/images/networks/pinterest.svg'
import networkImageTumblr from 'static/images/networks/tumblr2.svg'
import networkImageSnapchat from 'static/images/networks/snapchat2.svg'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import SelectPlanButton from '../Home/components/SelectPlanButton'

const NETWORKS = [
  { image: networkImageBluesky, pagePath: '/integrations/bluesky' },
  { image: networkImageFacebook, pagePath: '/integrations/facebook' },
  { image: networkImageInstagtam, pagePath: '/integrations/instagram' },
  { image: networkImageLinkedIn, pagePath: '/integrations/linkedin' },
  { image: networkImageTwitter, pagePath: '/integrations/twitter' },
  { image: networkImageGoogle, pagePath: '/integrations/google' },
  { image: networkImageTikTok, pagePath: '/integrations/tiktok' },
  { image: networkImageYouTube, pagePath: '/integrations/youtube' },
  { image: networkImageReddit, pagePath: '/integrations/reddit' },
  { image: networkImagePinterest, pagePath: '/integrations/pinterest' },
  { image: networkImageSnapchat, pagePath: '/integrations/snapchat' },
  {
    image: networkImageTumblr,
    pagePath:
      'https://support.vistasocial.com/hc/en-us/articles/23458347603227--Web-Tumblr-Publishing-with-Vista-Social',
  },
]

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const StyledFeatureItemWrapper = styled(Flex)`
  background: ${colors.primary};
  padding: ${space.l};
`

const RoundedBox = styled(Box)`
  border-radius: ${radius.pill};
`

const StyledNetworksWrapper = styled(Flex)`
  margin-top: ${space.s};
  gap: ${space.s};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const GENERATE_SOCIAL_POSTS_ITEMS = [
  {
    description: 'Whether you’re writing captions, taglines, or posts, AI Assistant will help craft the perfect text.',
  },

  { description: 'Add fact-checked stats to your posts to increase your authority and credibility.' },
  { description: 'Quickly and more accurately translate your text to reach a global audience.' },
  {
    description:
      'Capture the attention of your followers by adding a joke to make your posts more memorable than straightforward marketing messages.',
  },
  { description: 'Stay consistent in tone, style, and messaging across each social profile and network.' },
]

const ASSISTANT_ITEMS = [
  {
    title: 'Time-saving',
    description:
      'Save time by getting help creating highly engaging and original social media content tailored to your target audience.',
  },
  {
    title: 'Consistency',
    description:
      'Ensure that your social media content is consistent in terms of tone, style, and messaging across all profiles and networks.',
  },
  {
    title: 'Creativity',
    description:
      'Breakthrough writer’s block with AI Assistant who will help you spark fresh ideas and inspiration for new social media content your followers will love.',
  },
  {
    title: 'Cost-effective',
    description: `Whether you're a single social media manager or director of a content team, AI Assistant helps you scale up without breaking the bank.`,
  },
]

const REPLY_ITEMS = [
  {
    description:
      'Quickly generate replies to incoming comments, DMs, reviews, and mentions across each profile and network.',
  },
  { description: 'Save and manage answers to common questions to provide a positive customer experience.' },
]

const AIAssistant = () => {
  return (
    <App fullHeader>
      <SEO
        title="AI Assistant powered by Chat GPT"
        description="Create better social media content 10x faster!"
        path="/ai-assistant/"
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="xl"
              mb={{ mobile: 0, desktop: 'xl' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex width="100%" justifyContent="center" flexDirection="column">
                    <Flex flexDirection={{ mobile: 'row', desktop: 'column' }} flexWrap="wrap" justifyContent="center">
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        <H1
                          fontSize="4xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          AI Assistant{' '}
                        </H1>
                        Powered by ChatGPT
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      pr={{ mobile: 0, desktop: 'l' }}
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      Vista Social is the world’s first social media management tool with ChatGPT technology! You can
                      create better posts 10x faster!
                    </H2>
                  </Flex>
                  <Flex flexDirection="column" mt="l" pt="m" alignItems={{ mobile: 'center', desktop: 'flex-start' }}>
                    <Text fontSize="l" color="secondaryText" textAlign="left">
                      Publish to:
                    </Text>
                    <StyledNetworksWrapper>
                      {NETWORKS.map(({ image, pagePath }, index) => (
                        <Link
                          href={pagePath}
                          key={pagePath}
                        >
                          <LocalImage
                            src={image}
                            alt="Your posts for the best performance on each social network."
                            width="32px"
                            height="32px"
                          />
                        </Link>
                      ))}
                    </StyledNetworksWrapper>
                  </Flex>
                  <SelectPlanButton />
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="611px"
                  alt="AI Assistant powered by Chat GPT"
                />
              </Flex>
            </Grid>
            <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
              <G2FeaturesComponent />
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="750px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Generate Engaging Social Posts{' '}
              </H1>
              Tailored To Your Target Audience In Seconds
            </H3>
          </Flex>
          <Flex
            mt="l"
            pt="m"
            justifyContent={{ mobile: 'center', desktop: 'space-between' }}
            flexDirection={{ mobile: 'column', desktop: 'row' }}
            alignItems="center"
          >
            <Flex mt={{ mobile: 'l', desktop: '0' }} maxWidth="640px" width="100%">
              <LocalImage
                src={imageGenerateSocialPosts}
                width="100%"
                alt="Generate Engaging Social Posts Tailored To Your Target Audience In Seconds"
              />
            </Flex>

            <Flex
              pt="xl"
              pb="xl"
              mt={{ mobile: 0, tablet: 'l', desktop: 0 }}
              ml={{ mobile: 0, tablet: 'l' }}
              flexDirection="column"
              justifyContent="space-between"
              height={{ mobile: 'auto', tablet: '670px' }}
            >
              {GENERATE_SOCIAL_POSTS_ITEMS.map(({ description }, index) => (
                <Flex
                  flexDirection="column"
                  my={{ mobile: 'm', tablet: 0 }}
                  key={index}
                  maxWidth="540px"
                  width="100%"
                  alignItems="baseline"
                >
                  <Flex alignItems="baseline">
                    <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                    <Text ml="s" fontSize="xl" color={COLOR_CONSTANTS.DENIM} textAlign="left">
                      {description}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.WHITE}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="790px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                AI Assistant Can Help You{' '}
              </H1>
              Brainstorm A Topic And{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Keep Your Content Fresh
              </H1>
            </H3>
            <Text mt="m" pt="s" color="secondaryText" fontSize="l">
              Ask for post ideas and pick the one that works best for you!
            </Text>
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }} as="a" href="/pricing">
            <LocalImage
              src={imageAIAssistantFeature}
              maxWidth="1233px"
              width="100%"
              mx="auto"
              alt="AI Assistant Can Help You Brainstorm A Topic And Keep Your Content Fresh"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="790px" flexDirection="column" mx="auto">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Create Professional And Consistent Replies{' '}
              </H1>
              To Build Trust With Your Followers
            </H3>
          </Flex>

          <Flex mt="xl" flexDirection={{ mobile: 'column', tablet: 'row' }} justifyContent="space-between">
            {REPLY_ITEMS.map(({ description }, index) => (
              <Flex flexDirection="column" my={{ mobile: 'm', tablet: 0 }} key={index} maxWidth="540px" width="100%">
                <Flex alignItems="baseline">
                  <Box borderRadius={radius.pill} width="8px" height="8px" minWidth="8px" bg={colors.primary} />
                  <Text ml="s" fontSize="xl" color={COLOR_CONSTANTS.DENIM} textAlign="left">
                    {description}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>

          <Flex mt="xl" justifyContent={{ mobile: 'center', desktop: 'space-between' }} as="a" href="/pricing">
            <LocalImage
              src={imageAIAssistantReplies}
              maxWidth="1093px"
              width="100%"
              mx="auto"
              alt="Create Professional And Consistent Replies To Build Trust With Your Followers"
            />
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.WHITE}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          pt={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="672px" mx="auto" justifyContent="center">
            <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
              Benefits of{' '}
              <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
                Using AI Assistant
              </H1>
            </H3>
          </Flex>
          <Flex mt="xl" width="100%" position="relative">
            <RoundedBox
              position="absolute"
              width="184px"
              height="184px"
              bg={COLOR_CONSTANTS.SALT}
              right="0"
              top={{ mobile: '-54px', tablet: '-74px' }}
              zIndex="0"
            />
            <RoundedBox
              position="absolute"
              width="39px"
              height="39px"
              bg={COLOR_CONSTANTS.SALT}
              right="15px"
              top="146px"
              zIndex="0"
            />
            <RoundedBox
              position="absolute"
              width="393px"
              height="393px"
              bg={COLOR_CONSTANTS.SALT}
              left="-76px"
              bottom="-70px"
              zIndex="0"
            />
            <Grid
              zIndex="1"
              mx="auto"
              gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, auto)' }}
              gridGap="m"
            >
              {ASSISTANT_ITEMS.map(({ title, description }, index) => {
                const isFirstColumn = index % 2 === 0

                let borderRadius = `${space.xm} ${space.xm} ${space.xs} ${space.xm}`

                if (index === 1) {
                  borderRadius = `${space.xm} ${space.xm} ${space.xm} ${space.xs}`
                } else if (index === 2) {
                  borderRadius = `${space.xm} ${space.xs} ${space.xm} ${space.xm}`
                } else if (index === 3) {
                  borderRadius = `${space.xs} ${space.xm} ${space.xm} ${space.xm}`
                }

                return (
                  <StyledFeatureItemWrapper
                    flexDirection="column"
                    key={title}
                    width={{ mobile: 'auto', desktop: isFirstColumn ? '500px' : '400px' }}
                    borderRadius={{
                      mobile: `${space.xm}`,
                      tablet: borderRadius,
                    }}
                  >
                    <Flex flexDirection="column" mx="xs" p="s">
                      <Flex alignItems="center">
                        <LocalImage src={imageStar} width="18px" height="18px" alt={title} />
                        <Text ml="s" px="xs" color={colors.white} fontWeight="bold" fontSize="xl">
                          {title}
                        </Text>
                      </Flex>
                      <Text mt="m" fontSize="m" color={COLOR_CONSTANTS.SALT} textAlign="left">
                        {description}
                      </Text>
                    </Flex>
                  </StyledFeatureItemWrapper>
                )
              })}
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Flex mt="xxl" width="100%" alignItems="center" justifyContent="center">
        <NewsletterSubScription type="ai_assistant" />
      </Flex>

      <Footer />
    </App>
  )
}

export default AIAssistant
